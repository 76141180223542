<template>
    <div class="container">
        <div class="lang-box">
            <div class="lang">
                <img v-if="info.return_url" class="back" src="../assets/back-arrow.png"
                    @click="returnUrl(info.return_url)" />
                <div v-else></div>
                <div class="current-lang" @click="isDown = !isDown">
                    {{currentLang}}
                    <img v-if="isDown" class="arrow" src="../assets/down-arrow.png" />
                    <img v-else class="arrow" src="../assets/up-arrow.png" />
                </div>
                <div v-if="!isDown" class="lang-list">
                    <div v-for="(item,index) in langs" :key="index" class="lang-item" @click="changeLang(item)">
                        {{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="warning">
            <img class="icon" src="../assets/warning.png" />
            {{langPack.WarningTips}}
        </div>
        <div class="main">
            <div v-if="info.status == 3" class="payment-time">
                {{langPack.Payment}}:
                <span class="time">{{countdown}}</span>
            </div>
            <div class="qrcode-box">
                <div class="coin-info">
                    <img class="icon" :src="currentCoin(info.coinId).icon" />
                    <div class="right">
                        {{langPack.Scan}}
                        <span class="coin">({{currentCoin(info.coinId).coinName}})</span>
                    </div>
                </div>
                <div class="amount">
                    {{orderAmount}}
                    <img class="copy" src="../assets/copy.png" @click="copy(String(orderAmount))" />
                </div>
                <div class="order-number">{{langPack.OrderNumber}}: {{info.out_trade_no}}</div>
                <img class="qrcode" :src="qrcodeURL" />
                <div class="address">
                    {{info.address}}
                    <img class="copy" src="../assets/copy.png" @click="copy(info.address)" />
                </div>
                <div v-if="info.status != 3 || info.status == 3&&info.expire <= 0" class="end-mask">
                    <img class="clock" :src="currentStatus.icon" />
                    <div class="end-tips">{{currentStatus.title}}</div>
                </div>
            </div>
            <div class="desc">
                <p class="desc-title">{{langPack.Description}}</p>
                <p>
                    {{langPack.DescriptionInfo1[0]}}
                    {{currentCoin(info.coinId).coinName}}({{currentCoin(info.coinId).blockName}})
                    {{langPack.DescriptionInfo1[1]}}
                </p>
                <p>{{langPack.DescriptionInfo2}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import langList from '../utils/lang.js'
    import coinList from '../utils/coinList.js'
    import {
        order
    } from '@/request/api.js'
    import qrcode from '../utils/weapp-qrcode.js'
    import { Message } from 'element-ui'
    export default {
        data() {
            return {
                currentLang: '',
                langPack: {},
                isDown: true,
                info: {
                    lang: "en",
                    address: "",
                    amount: "",
                    coinId: "",
                    out_trade_no: "",
                    trade_no: "",
                    status: "",
                    return_url: "",
                    decimal: "",
                    expire: 10000
                },
                qrcodeURL: '',
                // nowTime: 0,
                // endTime: 0,
                // endDate: '2022-08-05 16:00:00',
                timer: null,
                countdown: '',
                orderTimer: null,
                orderIntervalTime: 10000
            }
        },
        created() {
            // console.log(this.$route.query)
            let querys = this.$route.query
            if (querys) {
                for (let [key, value] of Object.entries(querys)) {
                    this.info[key] = value
                }
            }

            this.initLang()
            this.getQrcode()

            this.getCountdown()
            this.timer = setInterval(() => {
                this.info.expire -= 1000
                this.getCountdown()
            }, 1000)

            this.getOrderInfo()
            this.orderTimer = setInterval(() => {
                this.getOrderInfo()
            }, this.orderIntervalTime)
        },
        computed: {
            langs() {
                return langList
            },

            currentCoin() {
                return (coin_id) => {
                    let coin = {}
                    coinList.forEach(item => {
                        if (coin_id == item.coinId) {
                            coin = item
                        }
                    })
                    return coin
                }
            },

            orderAmount() {
                let amount = this.info.amount ? parseFloat(this.info.amount) : 0
                let decimal = this.info.decimal ? this.info.decimal : this.currentCoin(this.info.coinId).decimal || 0
                return parseFloat(this.$NP.divide(amount, Math.pow(10, decimal)))
            },

            currentStatus() {
                let status_info = {
                    title: '',
                    icon: ''
                }
                if (this.info.status == 1) {
                    status_info.title = this.langPack.OrderSuccess
                    status_info.icon = require('@/assets/success.png')
                } else if (this.info.status == 0) {
                    status_info.title = this.langPack.OrderReview
                    status_info.icon = require('@/assets/review.png')
                } else if (this.info.status == -1) {
                    status_info.title = this.langPack.OrderClosedTips
                    status_info.icon = require('@/assets/fail.png')
                } else if (this.info.status == 3 && this.info.expire <= 0) {
                    status_info.title = this.langPack.OrderResubmit
                    status_info.icon = require('@/assets/clock.png')
                }
                return status_info
            }
        },
        destroyed() {
            clearInterval(this.timer)
            clearInterval(this.orderTimer)
        },
        methods: {
            initLang() {
                let current_lang = this.info.lang
                if (!current_lang) {
                    this.currentLang = langList[0].name
                    this.langPack = langList[0].value
                } else {
                    langList.forEach(item => {
                        if (current_lang == item.key) {
                            this.currentLang = item.name
                            this.langPack = item.value
                        }
                    })
                }
            },

            changeLang(item) {
                this.currentLang = item.name
                this.langPack = item.value
                this.isDown = true
            },

            returnUrl(url) {
                location.href = url
            },

            getOrderInfo() {
                order({
                    trade_no: this.info.trade_no
                }).then(res => {
                    if (this.info.status != 0 && this.info.status != 3) {
                        clearInterval(this.orderTimer)  
                    }
                    for (let [key, value] of Object.entries(res.data)) {
                        if (key == 'expire') {
                            this.info[key] = value * 1000
                        } else {
                            this.info[key] = value
                        }
                    }
                    this.getQrcode()
                })
            },

            getQrcode() {
                let imgData = qrcode.drawImg(this.info.address, {
                    typeNumber: 4, // 密度
                    errorCorrectLevel: 'L', // 纠错等级
                    size: 800, // 白色边框
                })

                this.qrcodeURL = imgData
            },

            getCountdown() {
                this.countdown = this.handleCountdown(this.info.expire)
            },

            handleCountdown(timestamp) {
                if (timestamp > 0) {
                    let runTime = timestamp / 1000
                    // let day = Math.floor(runTime / 86400) >= 10 ? Math.floor(runTime / 86400) : ('0' + Math.floor(runTime / 86400));
                    // runTime = runTime % 86400;
                    let hour = Math.floor(runTime / 3600) >= 10 ? Math.floor(runTime / 3600) : ('0' + Math.floor(
                        runTime / 3600));
                    runTime = runTime % 3600;
                 		 let minute = Math.floor(runTime / 60) >= 10 ? Math.floor(runTime / 60) : ('0' + Math.floor(runTime /
                        60));
                    runTime = runTime % 60;
                    let second = runTime >= 10 ? runTime : ('0' + runTime);

                    return hour + ':' + minute + ':' + second
                } else {
                    clearInterval(this.timer)
                    return '00:00:00'
                }
            },

            // async copy(text) {
            // 	let target = document.createElement('input') //创建input节点
            // 	target.value = text // 给input的value赋值
            // 	document.body.appendChild(target) // 向页面插入input节点
            // 	target.select() // 选中input
            // 	try {
            // 		await document.execCommand('Copy') // 执行浏览器复制命令
            //         target.style.display = 'none'
            // 		alert(this.langPack.CopySuccessfully)
            // 	} catch {
            //         target.style.display = 'none'
            //         alert(this.langPack.ReplicationFailed)
            // 	}
            // }
            copy(text) {
                let input = document.createElement("input")
                input.value = text
                document.body.appendChild(input)
                input.select()
                input.setSelectionRange(0, input.value.length)
                document.execCommand('Copy')
                document.body.removeChild(input)
                Message({
                    message: this.langPack.CopySuccessfully,
                    type: 'success',
                    duration: 2000
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        width: 100%;
        min-height: 100vh;
        background-color: #f6f6f6;

        .lang-box {
            width: 100%;
            box-sizing: border-box;
            padding: 0.84rem 1.25rem;

            .lang {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 1000px;
                margin: 0 auto;

                .back {
                    min-width: 2rem;
                    max-width: 2rem;
                    height: 2rem;
                }

                .current-lang {
                    display: flex;
                    align-items: center;
                    font-size: 1.17rem;
                    color: #333333;
                    white-space: nowrap;

                    .arrow {
                        min-width: 0.84rem;
                        max-width: 0.84rem;
                        margin-left: 4px;
                    }
                }

                .lang-list {
                    position: absolute;
                    top: calc(100% + 0.42rem);
                    right: -5px;
                    z-index: 20;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 1px solid #dddddd;
                    border-radius: 0 0 4px 4px;
                    background-color: #ffffff;

                    .lang-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0.66rem;
                        font-size: 1.17rem;
                        color: #333333;
                        white-space: nowrap;

                        &:not(:last-child) {
                            border-bottom: 1px solid #dddddd;
                        }
                    }
                }
            }
        }

        .warning {
            width: 100%;
            max-width: 1000px;
            box-sizing: border-box;
            padding: 1rem 1.25rem;
            line-height: 120%;
            margin: 0 auto;
            font-size: 1.1rem;
            color: #ff443e;
            text-align: center;
            word-wrap: break-word;
            background-color: #faf2d2;

            .icon {
                min-width: 1.17rem;
                max-width: 1.17rem;
                height: 1.17rem;
                margin-right: 2px;
            }
        }

        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 0 0.84rem 1.67rem;

            .payment-time {
                width: 100%;
                max-width: 600px;
                margin: 1.17rem auto 0;
                font-size: 1.1rem;
                color: #666666;
                text-align: center;
                word-wrap: break-word;

                .time {
                    margin-left: 4px;
                    font-size: 1.59rem;
                    font-weight: bold;
                    color: #ff443e;
                }
            }

            .qrcode-box {
                position: relative;
                width: 100%;
                max-width: 600px;
                box-sizing: border-box;
                padding: 1.67rem 1.34rem 2rem;
                margin: 1.17rem auto;
                border-radius: 6px;
                background-color: #ffffff;

                .coin-info {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .icon {
                        min-width: 1.59rem;
                        max-width: 1.59rem;
                        height: 1.59rem;
                        margin-right: 6px;
                    }

                    .right {
                        max-width: 100%;
                        font-size: 1.25rem;
                        font-weight: bold;
                        color: #333333;
                        word-wrap: break-word;

                        .coin {
                            margin-left: 3px;
                            color: #ff443e;
                        }
                    }
                }

                .amount {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin: 0.6rem 0;
                    font-size: 2rem;
                    // font-weight: bold;
                    color: #000000;
                    word-break: break-all;
                }

                .copy {
                    min-width: 1.5rem;
                    max-width: 1.5rem;
                    height: 1.5rem;
                    margin-left: 8px;
                }

                .order-number {
                    width: 100%;
                    font-size: 1rem;
                    color: #666666;
                    text-align: center;
                    word-break: break-all;
                }

                .qrcode {
                    display: block;
                    min-width: 12.1rem;
                    max-width: 12.1rem;
                    height: 12.1rem;
                    margin: 1.67rem auto;
                }

                .address {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-size: 1rem;
                    color: #666666;
                    word-break: break-all;
                }

                .end-mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0 5rem;
                    background-color: rgba(255, 255, 255, .9);

                    .clock {
                        min-width: 2.84rem;
                        max-width: 2.84rem;
                        height: 2.84rem;
                        margin-bottom: 20px;
                    }

                    .end-tips {
                        width: 100%;
                        line-height: 160%;
                        font-size: 1.17rem;
                        font-weight: bold;
                        color: #000000;
                        text-align: center;
                        word-wrap: break-word;
                    }
                }
            }

            .desc {
                width: 100%;
                max-width: 1000px;
                margin: 0 auto;
                font-size: 1.1rem;
                color: #1296db;
                word-wrap: break-word;

                p {
                    margin: 0.84rem 0;
                }

                .desc-title {
                    font-size: 1.17rem;
                    // font-weight: bold;
                }
            }
        }
    }
</style>
